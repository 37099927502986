var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","content-class":"list-border top zg-select-border-radius","top":_vm.position == 'top',"bottom":_vm.position == 'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({attrs:{"shrink":""}},'v-layout',attrs,false),on),[_c('DefaultButton',{class:['my-0'],style:({
          marginRight: _vm.icon
            ? _vm.customRightMargin
            : _vm.customRightMargin
            ? _vm.customRightMargin
            : undefined,
        }),attrs:{"bgColor":!_vm.icon && _vm.color,"color":_vm.icon ? 'black' : 'white',"icon":_vm.icon,"disabled":_vm.loading,"small":_vm.icon || _vm.small,"primary":_vm.primary}},[(!_vm.icon)?_c('span',[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e(),(_vm.icon)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-down")]):_vm._e()],1)],1)]}}],null,true)},_vm._l((_vm.groups),function(group,groupIndex){return _c('v-list',{key:groupIndex,staticClass:"pb-0",attrs:{"subheader":"","dense":""}},[(group.title)?_c('v-subheader',{staticClass:"subheader text-body-1 mx-2"},[_vm._v(_vm._s(group.title))]):_vm._e(),_vm._l((group.items),function(item,index){return [_c('v-tooltip',{key:index,attrs:{"left":"","allow-overflow":"","disabled":!item.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){$event.preventDefault();return item.action.apply(null, arguments)}}},on),[_c('v-list-item-title',{staticClass:"zg-item-title"},[_vm._v(_vm._s(item.title))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])])]}),(groupIndex != _vm.groups.length - 1)?_c('v-divider'):_vm._e()],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }