<template>
  <v-menu
    offset-y
    content-class="list-border top zg-select-border-radius"
    :top="position == 'top'"
    :bottom="position == 'bottom'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-layout shrink v-on="on" v-bind="attrs">
        <DefaultButton
          :bgColor="!icon && color"
          :color="icon ? 'black' : 'white'"
          :class="['my-0']"
          :style="{
            marginRight: icon
              ? customRightMargin
              : customRightMargin
              ? customRightMargin
              : undefined,
          }"
          :icon="icon"
          :disabled="loading"
          :small="icon || small"
          :primary="primary"
        >
          <span v-if="!icon">
            <slot>{{ label }}</slot>
          </span>
          <v-icon small v-if="icon">mdi-chevron-down</v-icon>
        </DefaultButton>
      </v-layout>
    </template>
    <v-list
      subheader
      dense
      v-for="(group, groupIndex) in groups"
      :key="groupIndex"
      class="pb-0"
    >
      <v-subheader class="subheader text-body-1 mx-2" v-if="group.title">{{
        group.title
      }}</v-subheader>
      <template v-for="(item, index) in group.items">
        <v-tooltip left allow-overflow :disabled="!item.tooltip" :key="index">
          <template v-slot:activator="{ on }">
            <v-list-item @click.prevent="item.action" v-on="on">
              <v-list-item-title class="zg-item-title">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </template>
      <v-divider v-if="groupIndex != groups.length - 1"></v-divider>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    groups: {},
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    customRightMargin: {
      type: String,
      default: "8px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Więcej...",
    },
    position: {
      type: String,
      default: "top",
    },
    color: {
      type: String,
      default: "primary",
    },
    primary: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
};
</script>

<style lang="css" scoped>
.list-border {
  z-index: 203 !important;
}
.subheader {
  color: black !important;
  font-weight: bold !important;
}
.zg-item-title {
  font-size: 14px !important;
}
</style>